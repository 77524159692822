import React, { memo } from "react";
import Paper from "@material-ui/core/Paper";

const Blockquote = (props) => (
  <Paper
    style={{
      borderLeft: `6px solid ${!!props.color ? props.color : "#005282"}`,
      background: "#f4f4f4",
      fontSize: "1em",
      fontWeight: "400",
      fontFamily: "Inconsolata",
      whiteSpace: "pre",
      // display: "inline-block",
      padding: 8,
      textAlign: "left",
      maxWidth: "100%",
      overflow: "auto",
      overflowX: "auto",
      lineHeight: "1.5",
    }}
    {...props}
  />
);

export default memo(Blockquote);

/*

import BlockQuote from '../components/BlockQuote';

<BlockQuote>
https://spf-api.com/api/v2/
</BlockQuote>
*/

/*

import BlockQuote from './components';

<BlockQuote>
Authorization: Basic 4N284jkyYzNkMmYyNzcyODBkYjU5NWY2MzZiYjE5MGU21F0YjUxM0NkM3ZhYk03UkMcDc6czIvYWJjZGVmZ2h
</BlockQuote>
*/
